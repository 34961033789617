import React, { useState } from 'react';
import { Calculator, DollarSign, DoorClosed, AlertTriangle } from 'lucide-react';
import Footer from '../components/Footer';
import ShareSection from '../components/ShareSection';
import { DoorConfigPanel } from '../components/doors/DoorConfigPanel';
import { DoorBreakdown } from '../components/doors/DoorBreakdown';
import { DoorList } from '../components/doors/DoorList';
import { calculateDoorEstimate } from '../utils/doorCalculations';
import type { DoorConfig } from '../types/doors';

export default function DoorCalculator() {
  const [doorConfigs, setDoorConfigs] = useState<DoorConfig[]>([]);
  const [currentConfig, setCurrentConfig] = useState<DoorConfig>({
    id: '1',
    type: 'entry',
    material: 'steel',
    style: 'panel',
    grade: 'standard',
    details: {
      width: '36',
      height: '80',
      quantity: '1',
      includeFrame: true,
      includeHardware: true,
      includeFinishing: true,
      needsStructuralWork: false,
      includeScreens: false,
      includeGrilles: false,
      grillPattern: undefined,
      paintGrade: 'standard',
      paintColor: undefined,
      paintFinish: 'satin',
      customColor: false,
      prePainted: false,
      includeWeatherstripping: true,
      includeThreshold: true,
      includeLockset: true,
      includeHinges: true,
      needsJambRepair: false,
      needsHeaderRepair: false,
      includeStorm: false,
      includeSecurity: false
    }
  });

  const addDoorConfig = () => {
    setDoorConfigs([...doorConfigs, {
      ...currentConfig,
      id: (doorConfigs.length + 1).toString()
    }]);
    setCurrentConfig({
      ...currentConfig,
      id: (doorConfigs.length + 2).toString(),
      details: {
        ...currentConfig.details,
        quantity: '1'
      }
    });
  };

  const removeDoorConfig = (id: string) => {
    setDoorConfigs(doorConfigs.filter(config => config.id !== id));
  };

  const results = calculateDoorEstimate(doorConfigs);

  return (
    <div className="min-h-screen bg-gradient-to-b from-amber-600 to-amber-800 p-6">
      <div className="max-w-2xl mx-auto bg-white rounded-xl shadow-lg p-6">
        <div className="flex items-center justify-center mb-6">
          <DoorClosed className="w-8 h-8 text-amber-600 mr-2" />
          <h1 className="text-2xl font-bold text-gray-800">Door Calculator</h1>
        </div>

        <div className="space-y-6">
          <DoorConfigPanel
            config={currentConfig}
            onChange={setCurrentConfig}
          />

          <button
            onClick={addDoorConfig}
            className="w-full py-2 px-4 bg-amber-600 text-white rounded-lg hover:bg-amber-700 transition flex items-center justify-center space-x-2"
          >
            <span>Add Door to Quote</span>
          </button>

          {doorConfigs.length > 0 && (
            <DoorList
              configs={doorConfigs}
              onRemove={removeDoorConfig}
            />
          )}

          {results && doorConfigs.length > 0 && (
            <DoorBreakdown results={results} />
          )}

          <div className="bg-amber-50 p-4 rounded-lg space-y-2">
            <div className="flex items-start space-x-2">
              <AlertTriangle className="w-5 h-5 text-amber-500 flex-shrink-0 mt-0.5" />
              <div className="text-sm text-amber-800">
                <p>* Estimates based on average Minster/Maria Stein area rates. Actual costs may vary based on specific conditions.</p>
                <p>* Additional costs may apply for custom sizes or special requirements.</p>
                <p>* Professional measurement recommended for accurate pricing.</p>
              </div>
            </div>
          </div>

          <div className="bg-white p-4 rounded-lg shadow-sm">
            <h3 className="text-lg font-semibold text-gray-900 mb-4">Local Door Specialist</h3>
            <div className="space-y-4">
              <div className="border-b pb-4">
                <h4 className="font-semibold text-amber-700">
                  <a href="https://moellerdoor.com" target="_blank" rel="noopener noreferrer" 
                     className="hover:text-amber-600">
                    Moeller Door & Window
                  </a>
                </h4>
                <p className="text-gray-600">(419) 925-4748</p>
                <p className="text-gray-600">5970 OH-119, St Henry, OH 45883</p>
                <p className="text-sm text-gray-500 mt-1">Expert door and window installation serving the Minster/Maria Stein area</p>
              </div>
            </div>
          </div>
        </div>

        <Footer />
        <ShareSection currentUrl={window.location.href} />
      </div>
    </div>
  );
}