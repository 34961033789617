import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import ErrorBoundary from './components/ErrorBoundary';
import ToolIndex from './pages/ToolIndex';
import HomeValueCalculator from './pages/HomeValueCalculator';
import HomeInsuranceCalculator from './pages/HomeInsuranceCalculator';
import GolfScore from './pages/GolfScore';
import ExcavationCalculator from './pages/ExcavationCalculator';
import PlumbingEstimator from './pages/PlumbingEstimator';
import ElectricalEstimator from './pages/ElectricalEstimator';
import FarmCalculator from './pages/FarmCalculator';
import ConcreteCalculator from './pages/ConcreteCalculator';
import LandscapingEstimator from './pages/LandscapingEstimator';
import HVACEstimator from './pages/HVACEstimator';
import AutoRepairCalculator from './pages/AutoRepairCalculator';
import RoofingCalculator from './pages/RoofingCalculator';
import PaintingEstimator from './pages/PaintingEstimator';
import WindowCalculator from './pages/WindowCalculator';
import DeckCalculator from './pages/DeckCalculator';
import FencingCalculator from './pages/FencingCalculator';
import SepticCalculator from './pages/SepticCalculator';
import WellDrillingCalculator from './pages/WellDrillingCalculator';
import SolarCalculator from './pages/SolarCalculator';
import AsphaltCalculator from './pages/AsphaltCalculator';
import PoolCalculator from './pages/PoolCalculator';
import GarageCalculator from './pages/GarageCalculator';
import BasementCalculator from './pages/BasementCalculator';
import KitchenCalculator from './pages/KitchenCalculator';
import BathroomCalculator from './pages/BathroomCalculator';
import SidingCalculator from './pages/SidingCalculator';
import CleaningCalculator from './pages/CleaningCalculator';
import InsulationCalculator from './pages/InsulationCalculator';
import FlooringCalculator from './pages/FlooringCalculator';
import GeneralContractorCalculator from './pages/GeneralContractorCalculator';
import HandymanCalculator from './pages/HandymanCalculator';
import ToolRentalCalculator from './pages/ToolRentalCalculator';
import WeddingCalculator from './pages/WeddingCalculator';
import DoorCalculator from './pages/DoorCalculator';
import MortgageStrategyCalculator from './pages/MortgageStrategyCalculator';
import CabinetCalculator from './pages/CabinetCalculator';
import LegalCalculator from './pages/LegalCalculator';
import PhysicalTherapyCalculator from './pages/PhysicalTherapyCalculator';
import AccountingCalculator from './pages/AccountingCalculator';

export default function App() {
  return (
    <ErrorBoundary>
      <Layout>
        <Routes>
          <Route path="/" element={<ToolIndex />} />
          <Route path="/home-value" element={<HomeValueCalculator />} />
          <Route path="/home-insurance" element={<HomeInsuranceCalculator />} />
          <Route path="/golf-score" element={<GolfScore />} />
          <Route path="/excavation" element={<ExcavationCalculator />} />
          <Route path="/plumbing" element={<PlumbingEstimator />} />
          <Route path="/electrical" element={<ElectricalEstimator />} />
          <Route path="/farm" element={<FarmCalculator />} />
          <Route path="/concrete" element={<ConcreteCalculator />} />
          <Route path="/landscaping" element={<LandscapingEstimator />} />
          <Route path="/hvac" element={<HVACEstimator />} />
          <Route path="/auto-repair" element={<AutoRepairCalculator />} />
          <Route path="/roofing" element={<RoofingCalculator />} />
          <Route path="/painting" element={<PaintingEstimator />} />
          <Route path="/windows" element={<WindowCalculator />} />
          <Route path="/deck" element={<DeckCalculator />} />
          <Route path="/fencing" element={<FencingCalculator />} />
          <Route path="/septic" element={<SepticCalculator />} />
          <Route path="/well-drilling" element={<WellDrillingCalculator />} />
          <Route path="/solar" element={<SolarCalculator />} />
          <Route path="/asphalt" element={<AsphaltCalculator />} />
          <Route path="/pool" element={<PoolCalculator />} />
          <Route path="/garage" element={<GarageCalculator />} />
          <Route path="/basement" element={<BasementCalculator />} />
          <Route path="/kitchen" element={<KitchenCalculator />} />
          <Route path="/bathroom" element={<BathroomCalculator />} />
          <Route path="/siding" element={<SidingCalculator />} />
          <Route path="/cleaning" element={<CleaningCalculator />} />
          <Route path="/insulation" element={<InsulationCalculator />} />
          <Route path="/flooring" element={<FlooringCalculator />} />
          <Route path="/general-contractor" element={<GeneralContractorCalculator />} />
          <Route path="/handyman" element={<HandymanCalculator />} />
          <Route path="/tool-rental" element={<ToolRentalCalculator />} />
          <Route path="/wedding" element={<WeddingCalculator />} />
          <Route path="/door-calculator" element={<DoorCalculator />} />
          <Route path="/mortgage-strategy" element={<MortgageStrategyCalculator />} />
          <Route path="/cabinet" element={<CabinetCalculator />} />
          <Route path="/legal" element={<LegalCalculator />} />
          <Route path="/physical-therapy" element={<PhysicalTherapyCalculator />} />
          <Route path="/accounting" element={<AccountingCalculator />} />
        </Routes>
      </Layout>
    </ErrorBoundary>
  );
}