import React, { useState } from 'react';
import { Grid, Calculator, DollarSign, Ruler, AlertTriangle } from 'lucide-react';
import Footer from '../components/Footer';
import ShareSection from '../components/ShareSection';

type CabinetType = 'base' | 'wall' | 'pantry' | 'island';
type MaterialGrade = 'standard' | 'premium' | 'luxury';
type WoodType = 'maple' | 'oak' | 'cherry' | 'walnut' | 'birch';

export default function CabinetCalculator() {
  // State for cabinet details
  const [cabinetType, setCabinetType] = useState<CabinetType>('base');
  const [materialGrade, setMaterialGrade] = useState<MaterialGrade>('standard');
  const [woodType, setWoodType] = useState<WoodType>('maple');
  const [width, setWidth] = useState('');
  const [height, setHeight] = useState('');
  const [depth, setDepth] = useState('');
  const [quantity, setQuantity] = useState('1');
  const [includeDrawers, setIncludeDrawers] = useState(true);
  const [includeShelves, setIncludeShelves] = useState(true);
  const [includeHardware, setIncludeHardware] = useState(true);
  const [customFinish, setCustomFinish] = useState(false);
  const [installation, setInstallation] = useState(false);

  // Current market rates (2024 Lima/Dayton area)
  const baseMaterialCosts = {
    maple: { standard: 85, premium: 125, luxury: 175 }, // per sq ft
    oak: { standard: 75, premium: 115, luxury: 165 },
    cherry: { standard: 95, premium: 145, luxury: 195 },
    walnut: { standard: 115, premium: 165, luxury: 225 },
    birch: { standard: 65, premium: 95, luxury: 145 }
  };

  const cabinetMultipliers = {
    base: 1.0,
    wall: 0.9,
    pantry: 1.2,
    island: 1.3
  };

  const additionalCosts = {
    drawers: {
      standard: 75, // per drawer
      premium: 125,
      luxury: 175
    },
    shelves: {
      standard: 45, // per shelf
      premium: 65,
      luxury: 95
    },
    hardware: {
      standard: 25, // per piece
      premium: 45,
      luxury: 75
    },
    finish: {
      standard: 15, // per sq ft
      premium: 25,
      luxury: 35
    },
    installation: 85 // per linear ft
  };

  const calculateEstimate = () => {
    const w = parseFloat(width);
    const h = parseFloat(height);
    const d = parseFloat(depth);
    const qty = parseInt(quantity);
    if (!w || !h || !d || !qty) return null;

    // Calculate surface area and linear feet
    const surfaceArea = ((w * h * 2) + (w * d * 2) + (h * d * 2)) / 144; // Convert to sq ft
    const linearFeet = w / 12;

    // Calculate base material cost
    const materialCost = surfaceArea * baseMaterialCosts[woodType][materialGrade] * 
                        cabinetMultipliers[cabinetType] * qty;

    // Calculate additional features
    const drawerCount = includeDrawers ? Math.floor(h / 6) : 0; // One drawer per 6 inches height
    const drawerCost = includeDrawers ? 
      (drawerCount * additionalCosts.drawers[materialGrade] * qty) : 0;

    const shelfCount = includeShelves ? Math.floor(h / 12) : 0; // One shelf per foot
    const shelfCost = includeShelves ?
      (shelfCount * additionalCosts.shelves[materialGrade] * qty) : 0;

    const hardwarePieces = (includeDrawers ? drawerCount : 0) + 2; // Handles/knobs
    const hardwareCost = includeHardware ?
      (hardwarePieces * additionalCosts.hardware[materialGrade] * qty) : 0;

    const finishCost = customFinish ?
      (surfaceArea * additionalCosts.finish[materialGrade] * qty) : 0;

    const installationCost = installation ?
      (linearFeet * additionalCosts.installation * qty) : 0;

    // Calculate totals
    const subtotal = materialCost + drawerCost + shelfCost + hardwareCost + 
                    finishCost + installationCost;
    const tax = (materialCost + drawerCost + shelfCost + hardwareCost) * 0.075; // 7.5% sales tax
    const total = subtotal + tax;

    return {
      dimensions: {
        surface: surfaceArea.toFixed(1),
        linear: linearFeet.toFixed(1)
      },
      features: {
        drawers: drawerCount,
        shelves: shelfCount,
        hardware: hardwarePieces
      },
      costs: {
        materials: materialCost.toFixed(2),
        drawers: drawerCost.toFixed(2),
        shelves: shelfCost.toFixed(2),
        hardware: hardwareCost.toFixed(2),
        finish: finishCost.toFixed(2),
        installation: installationCost.toFixed(2)
      },
      tax: tax.toFixed(2),
      total: total.toFixed(2)
    };
  };

  const results = calculateEstimate();

  return (
    <div className="min-h-screen bg-gradient-to-b from-amber-700 to-amber-900 p-6">
      <div className="max-w-2xl mx-auto bg-white rounded-xl shadow-lg p-6">
        <div className="flex items-center justify-center mb-6">
          <Grid className="w-8 h-8 text-amber-700 mr-2" />
          <h1 className="text-2xl font-bold text-gray-800">Cabinet Calculator</h1>
        </div>

        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Cabinet Type</label>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
              {(['base', 'wall', 'pantry', 'island'] as const).map((type) => (
                <button
                  key={type}
                  onClick={() => setCabinetType(type)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    cabinetType === type
                      ? 'bg-amber-700 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {type}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Material Grade</label>
            <div className="grid grid-cols-3 gap-2">
              {(['standard', 'premium', 'luxury'] as const).map((grade) => (
                <button
                  key={grade}
                  onClick={() => setMaterialGrade(grade)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    materialGrade === grade
                      ? 'bg-amber-700 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {grade}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Wood Type</label>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
              {(['maple', 'oak', 'cherry', 'walnut', 'birch'] as const).map((wood) => (
                <button
                  key={wood}
                  onClick={() => setWoodType(wood)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    woodType === wood
                      ? 'bg-amber-700 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {wood}
                </button>
              ))}
            </div>
          </div>

          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Width (inches)</label>
              <input
                type="number"
                value={width}
                onChange={(e) => setWidth(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring focus:ring-amber-200"
                placeholder="Width"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Height (inches)</label>
              <input
                type="number"
                value={height}
                onChange={(e) => setHeight(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring focus:ring-amber-200"
                placeholder="Height"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Depth (inches)</label>
              <input
                type="number"
                value={depth}
                onChange={(e) => setDepth(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring focus:ring-amber-200"
                placeholder="Depth"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Quantity</label>
              <input
                type="number"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
                min="1"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring focus:ring-amber-200"
              />
            </div>
          </div>

          <div className="flex flex-col space-y-2">
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeDrawers}
                onChange={(e) => setIncludeDrawers(e.target.checked)}
                className="rounded text-amber-700 focus:ring-amber-500"
              />
              <span>Include Drawers</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeShelves}
                onChange={(e) => setIncludeShelves(e.target.checked)}
                className="rounded text-amber-700 focus:ring-amber-500"
              />
              <span>Include Shelves</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeHardware}
                onChange={(e) => setIncludeHardware(e.target.checked)}
                className="rounded text-amber-700 focus:ring-amber-500"
              />
              <span>Include Hardware</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={customFinish}
                onChange={(e) => setCustomFinish(e.target.checked)}
                className="rounded text-amber-700 focus:ring-amber-500"
              />
              <span>Custom Finish</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={installation}
                onChange={(e) => setInstallation(e.target.checked)}
                className="rounded text-amber-700 focus:ring-amber-500"
              />
              <span>Include Installation</span>
            </label>
          </div>

          {results && (
            <div className="bg-gray-50 rounded-lg p-6 mt-6">
              <div className="flex items-center justify-center mb-4">
                <Calculator className="w-6 h-6 text-amber-700 mr-2" />
                <h2 className="text-xl font-semibold text-gray-800">Cost Estimate</h2>
              </div>

              <div className="space-y-4">
                <div className="flex items-center justify-between text-sm">
                  <div className="flex items-center">
                    <Ruler className="w-4 h-4 text-gray-500 mr-2" />
                    <span className="text-gray-600">Surface Area:</span>
                  </div>
                  <span className="font-semibold">{results.dimensions.surface} sq ft</span>
                </div>

                <div className="border-t pt-4 space-y-2">
                  <div className="flex justify-between">
                    <span className="text-gray-600">Materials:</span>
                    <span className="font-semibold">${results.costs.materials}</span>
                  </div>
                  {parseFloat(results.costs.drawers) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Drawers ({results.features.drawers}):</span>
                      <span className="font-semibold">${results.costs.drawers}</span>
                    </div>
                  )}
                  {parseFloat(results.costs.shelves) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Shelves ({results.features.shelves}):</span>
                      <span className="font-semibold">${results.costs.shelves}</span>
                    </div>
                  )}
                  {parseFloat(results.costs.hardware) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Hardware:</span>
                      <span className="font-semibold">${results.costs.hardware}</span>
                    </div>
                  )}
                  {parseFloat(results.costs.finish) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Custom Finish:</span>
                      <span className="font-semibold">${results.costs.finish}</span>
                    </div>
                  )}
                  {parseFloat(results.costs.installation) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Installation:</span>
                      <span className="font-semibold">${results.costs.installation}</span>
                    </div>
                  )}
                </div>

                <div className="border-t pt-4 space-y-2">
                  <div className="flex justify-between">
                    <span className="text-gray-600">Sales Tax:</span>
                    <span className="font-semibold">${results.tax}</span>
                  </div>
                </div>

                <div className="border-t pt-4 flex justify-between items-center">
                  <div className="flex items-center">
                    <DollarSign className="w-5 h-5 text-amber-700 mr-2" />
                    <span className="font-bold">Total Estimate:</span>
                  </div>
                  <span className="text-2xl font-bold text-amber-700">${results.total}</span>
                </div>
              </div>
            </div>
          )}

          <div className="bg-amber-50 p-4 rounded-lg space-y-2">
            <div className="flex items-start space-x-2">
              <AlertTriangle className="w-5 h-5 text-amber-500 flex-shrink-0 mt-0.5" />
              <div className="text-sm text-amber-800">
                <p>* Estimates based on average Lima/Dayton area rates. Actual costs may vary based on specific conditions.</p>
                <p>* Additional costs may apply for complex designs or special features.</p>
                <p>* Lead times may vary based on material availability and customization.</p>
              </div>
            </div>
          </div>

          <div className="prose prose-sm max-w-none">
            <h3 className="text-lg font-semibold text-gray-900">Understanding Cabinet Costs</h3>
            <p className="text-gray-700">
              Custom cabinet costs in the Lima/Dayton area vary based on several factors including material choice, construction method, and additional features. Basic cabinets typically range from $200-500 per linear foot, with premium and custom options costing significantly more.
            </p>
            <h4 className="text-md font-semibold text-gray-900 mt-4">Key Considerations</h4>
            <ul className="list-disc pl-5 text-gray-700">
              <li>Wood type and quality</li>
              <li>Construction method (face frame vs frameless)</li>
              <li>Hardware quality and finish</li>
              <li>Interior organization features</li>
              <li>Finish type and application method</li>
            </ul>
            <p className="text-gray-700 mt-4">
              For the most accurate estimate, we recommend consulting with local cabinet makers and discussing specific design requirements and material options.
            </p>
          </div>
        </div>

        <Footer />
        
        <ShareSection currentUrl={window.location.href} />
      </div>
    </div>
  );
}