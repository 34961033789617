import React, { useState } from 'react';
import { Scale, Calculator, DollarSign, AlertTriangle } from 'lucide-react';
import Footer from '../components/Footer';
import ShareSection from '../components/ShareSection';

type ServiceType = 'consultation' | 'litigation' | 'contract' | 'estate' | 'corporate' | 'family';
type Complexity = 'simple' | 'moderate' | 'complex';
type AttorneyLevel = 'junior' | 'mid' | 'senior' | 'partner';

export default function LegalCalculator() {
  // State for legal service details
  const [serviceType, setServiceType] = useState<ServiceType>('consultation');
  const [complexity, setComplexity] = useState<Complexity>('moderate');
  const [attorneyLevel, setAttorneyLevel] = useState<AttorneyLevel>('mid');
  const [hours, setHours] = useState('');
  const [needsParalegal, setNeedsParalegal] = useState(false);
  const [needsResearch, setNeedsResearch] = useState(false);
  const [includeFilingFees, setIncludeFilingFees] = useState(true);
  const [needsExpertWitness, setNeedsExpertWitness] = useState(false);

  // Current market rates (2024 Lima/Dayton area)
  const hourlyRates = {
    junior: {
      simple: 175,
      moderate: 200,
      complex: 225
    },
    mid: {
      simple: 225,
      moderate: 275,
      complex: 325
    },
    senior: {
      simple: 300,
      moderate: 375,
      complex: 450
    },
    partner: {
      simple: 400,
      moderate: 500,
      complex: 600
    }
  };

  const serviceFees = {
    consultation: { base: 250, hourly: false },
    litigation: { base: 2500, hourly: true },
    contract: { base: 750, hourly: true },
    estate: { base: 1500, hourly: true },
    corporate: { base: 2000, hourly: true },
    family: { base: 1000, hourly: true }
  };

  const additionalCosts = {
    paralegal: 125, // per hour
    research: 250, // per case
    filing: {
      consultation: 0,
      litigation: 350,
      contract: 150,
      estate: 250,
      corporate: 500,
      family: 300
    },
    expertWitness: {
      simple: 1500,
      moderate: 2500,
      complex: 4000
    }
  };

  const calculateEstimate = () => {
    const timeNeeded = parseFloat(hours) || 1;
    if (!timeNeeded) return null;

    // Calculate base cost
    const service = serviceFees[serviceType];
    const hourlyRate = hourlyRates[attorneyLevel][complexity];
    const baseCost = service.hourly ? 
      (service.base + (hourlyRate * timeNeeded)) : 
      service.base;

    // Calculate additional costs
    const paralegalCost = needsParalegal ? 
      (additionalCosts.paralegal * (timeNeeded * 0.5)) : 0;
    
    const researchCost = needsResearch ? additionalCosts.research : 0;
    
    const filingCost = includeFilingFees ? 
      additionalCosts.filing[serviceType] : 0;
    
    const expertCost = needsExpertWitness ? 
      additionalCosts.expertWitness[complexity] : 0;

    // Calculate totals
    const subtotal = baseCost + paralegalCost + researchCost + filingCost + expertCost;
    const total = subtotal;

    return {
      base: baseCost.toFixed(2),
      paralegal: paralegalCost.toFixed(2),
      research: researchCost.toFixed(2),
      filing: filingCost.toFixed(2),
      expert: expertCost.toFixed(2),
      total: total.toFixed(2),
      hourlyRate: hourlyRate.toFixed(2)
    };
  };

  const results = calculateEstimate();

  return (
    <div className="min-h-screen bg-gradient-to-b from-indigo-600 to-indigo-800 p-6">
      <div className="max-w-2xl mx-auto bg-white rounded-xl shadow-lg p-6">
        <div className="flex items-center justify-center mb-6">
          <Scale className="w-8 h-8 text-indigo-600 mr-2" />
          <h1 className="text-2xl font-bold text-gray-800">Legal Fee Calculator</h1>
        </div>

        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Service Type</label>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
              {(['consultation', 'litigation', 'contract', 'estate', 'corporate', 'family'] as const).map((type) => (
                <button
                  key={type}
                  onClick={() => setServiceType(type)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    serviceType === type
                      ? 'bg-indigo-600 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {type}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Case Complexity</label>
            <div className="grid grid-cols-3 gap-2">
              {(['simple', 'moderate', 'complex'] as const).map((level) => (
                <button
                  key={level}
                  onClick={() => setComplexity(level)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    complexity === level
                      ? 'bg-indigo-600 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {level}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Attorney Level</label>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
              {(['junior', 'mid', 'senior', 'partner'] as const).map((level) => (
                <button
                  key={level}
                  onClick={() => setAttorneyLevel(level)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    attorneyLevel === level
                      ? 'bg-indigo-600 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {level}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Estimated Hours</label>
            <input
              type="number"
              value={hours}
              onChange={(e) => setHours(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200"
              placeholder="Enter hours needed"
            />
          </div>

          <div className="flex flex-col space-y-2">
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={needsParalegal}
                onChange={(e) => setNeedsParalegal(e.target.checked)}
                className="rounded text-indigo-600 focus:ring-indigo-500"
              />
              <span>Include Paralegal Support</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={needsResearch}
                onChange={(e) => setNeedsResearch(e.target.checked)}
                className="rounded text-indigo-600 focus:ring-indigo-500"
              />
              <span>Include Legal Research</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeFilingFees}
                onChange={(e) => setIncludeFilingFees(e.target.checked)}
                className="rounded text-indigo-600 focus:ring-indigo-500"
              />
              <span>Include Filing Fees</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={needsExpertWitness}
                onChange={(e) => setNeedsExpertWitness(e.target.checked)}
                className="rounded text-indigo-600 focus:ring-indigo-500"
              />
              <span>Include Expert Witness</span>
            </label>
          </div>

          {results && (
            <div className="bg-gray-50 rounded-lg p-6 mt-6">
              <div className="flex items-center justify-center mb-4">
                <Calculator className="w-6 h-6 text-indigo-600 mr-2" />
                <h2 className="text-xl font-semibold text-gray-800">Fee Estimate</h2>
              </div>

              <div className="space-y-4">
                <div className="flex justify-between text-sm">
                  <span className="text-gray-600">Attorney Rate:</span>
                  <span className="font-semibold">${results.hourlyRate}/hour</span>
                </div>

                <div className="border-t pt-4 space-y-2">
                  <div className="flex justify-between">
                    <span className="text-gray-600">Base Legal Fees:</span>
                    <span className="font-semibold">${results.base}</span>
                  </div>
                  {parseFloat(results.paralegal) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Paralegal Support:</span>
                      <span className="font-semibold">${results.paralegal}</span>
                    </div>
                  )}
                  {parseFloat(results.research) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Legal Research:</span>
                      <span className="font-semibold">${results.research}</span>
                    </div>
                  )}
                  {parseFloat(results.filing) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Filing Fees:</span>
                      <span className="font-semibold">${results.filing}</span>
                    </div>
                  )}
                  {parseFloat(results.expert) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Expert Witness:</span>
                      <span className="font-semibold">${results.expert}</span>
                    </div>
                  )}
                </div>

                <div className="border-t pt-4 flex justify-between items-center">
                  <div className="flex items-center">
                    <DollarSign className="w-5 h-5 text-indigo-600 mr-2" />
                    <span className="font-bold">Total Estimate:</span>
                  </div>
                  <span className="text-2xl font-bold text-indigo-600">${results.total}</span>
                </div>
              </div>
            </div>
          )}

          <div className="bg-indigo-50 p-4 rounded-lg space-y-2">
            <div className="flex items-start space-x-2">
              <AlertTriangle className="w-5 h-5 text-indigo-500 flex-shrink-0 mt-0.5" />
              <div className="text-sm text-indigo-800">
                <p>* Estimates based on average Lima/Dayton area legal rates.</p>
                <p>* Actual fees may vary based on specific case details and requirements.</p>
                <p>* Additional costs may apply for court appearances, travel, or specialized services.</p>
              </div>
            </div>
          </div>
        </div>

        <Footer />
        
        <ShareSection currentUrl={window.location.href} />
      </div>
    </div>
  );
}