import React, { useState } from 'react';
import { Square, Calculator, DollarSign, Ruler, AlertTriangle } from 'lucide-react';
import Footer from '../components/Footer';
import ShareSection from '../components/ShareSection';

type FlooringType = 'hardwood' | 'laminate' | 'tile' | 'vinyl' | 'carpet';
type QualityGrade = 'economy' | 'standard' | 'premium';

interface Room {
  id: string;
  name: string;
  length: string;
  width: string;
  flooringType: FlooringType;
  qualityGrade: QualityGrade;
  includeUnderlayment: boolean;
  includeRemoval: boolean;
  includeDisposal: boolean;
  includeTrim: boolean;
  needsLeveling: boolean;
  estimate: {
    dimensions: {
      area: string;
      perimeter: string;
    };
    costs: {
      materials: string;
      labor: string;
      underlayment: string;
      removal: string;
      disposal: string;
      trim: string;
      leveling: string;
    };
    tax: string;
    total: string;
    perSqFt: string;
  } | null;
}

export default function FlooringCalculator() {
  // State for current room being configured
  const [currentRoom, setCurrentRoom] = useState<Room>({
    id: '1',
    name: 'Room 1',
    length: '',
    width: '',
    flooringType: 'hardwood',
    qualityGrade: 'standard',
    includeUnderlayment: true,
    includeRemoval: false,
    includeDisposal: false,
    includeTrim: true,
    needsLeveling: false,
    estimate: null
  });

  // State for all calculated rooms
  const [rooms, setRooms] = useState<Room[]>([]);

  // Current market rates (2024 Lima/Dayton area)
  const flooringCosts = {
    hardwood: {
      economy: 6.50,
      standard: 8.50,
      premium: 12.50
    },
    laminate: {
      economy: 3.50,
      standard: 4.75,
      premium: 6.50
    },
    tile: {
      economy: 5.50,
      standard: 7.50,
      premium: 11.50
    },
    vinyl: {
      economy: 3.75,
      standard: 5.25,
      premium: 7.50
    },
    carpet: {
      economy: 3.25,
      standard: 4.50,
      premium: 6.75
    }
  };

  const laborRates = {
    hardwood: 4.50,
    laminate: 3.50,
    tile: 6.50,
    vinyl: 3.00,
    carpet: 2.50
  };

  const additionalCosts = {
    underlayment: {
      hardwood: 1.25,
      laminate: 0.75,
      tile: 1.50,
      vinyl: 0.85,
      carpet: 0.95
    },
    removal: 1.75,
    disposal: 1.25,
    trim: {
      hardwood: 3.50,
      laminate: 2.75,
      tile: 4.50,
      vinyl: 2.50,
      carpet: 2.25
    },
    leveling: 2.25
  };

  const calculateRoomEstimate = (room: Room) => {
    const l = parseFloat(room.length);
    const w = parseFloat(room.width);
    if (!l || !w) return null;

    const floorArea = l * w;
    const perimeter = 2 * (l + w);
    
    const flooringCost = floorArea * flooringCosts[room.flooringType][room.qualityGrade];
    const laborCost = floorArea * laborRates[room.flooringType];

    const underlaymentCost = room.includeUnderlayment ? 
      (floorArea * additionalCosts.underlayment[room.flooringType]) : 0;

    const removalCost = room.includeRemoval ? (floorArea * additionalCosts.removal) : 0;
    const disposalCost = room.includeDisposal ? (floorArea * additionalCosts.disposal) : 0;
    
    const trimCost = room.includeTrim ? 
      (perimeter * additionalCosts.trim[room.flooringType]) : 0;

    const levelingCost = room.needsLeveling ? (floorArea * additionalCosts.leveling) : 0;

    const subtotal = flooringCost + laborCost + underlaymentCost + removalCost + 
                    disposalCost + trimCost + levelingCost;
    const tax = flooringCost * 0.075;
    const total = subtotal + tax;

    return {
      dimensions: {
        area: floorArea.toFixed(0),
        perimeter: perimeter.toFixed(0)
      },
      costs: {
        materials: flooringCost.toFixed(2),
        labor: laborCost.toFixed(2),
        underlayment: underlaymentCost.toFixed(2),
        removal: removalCost.toFixed(2),
        disposal: disposalCost.toFixed(2),
        trim: trimCost.toFixed(2),
        leveling: levelingCost.toFixed(2)
      },
      tax: tax.toFixed(2),
      total: total.toFixed(2),
      perSqFt: (total / floorArea).toFixed(2)
    };
  };

  const handleAddRoom = () => {
    const estimate = calculateRoomEstimate(currentRoom);
    if (!estimate) return;

    const completedRoom = { ...currentRoom, estimate };
    setRooms([...rooms, completedRoom]);

    // Reset current room with incremented ID and name
    setCurrentRoom({
      id: (rooms.length + 2).toString(),
      name: `Room ${rooms.length + 2}`,
      length: '',
      width: '',
      flooringType: 'hardwood',
      qualityGrade: 'standard',
      includeUnderlayment: true,
      includeRemoval: false,
      includeDisposal: false,
      includeTrim: true,
      needsLeveling: false,
      estimate: null
    });
  };

  const removeRoom = (roomId: string) => {
    setRooms(rooms.filter(room => room.id !== roomId));
  };

  const calculateTotalCost = () => {
    return rooms.reduce((total, room) => {
      return total + (room.estimate ? parseFloat(room.estimate.total) : 0);
    }, 0);
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-amber-700 to-amber-900 p-6">
      <div className="max-w-2xl mx-auto bg-white rounded-xl shadow-lg p-6">
        <div className="flex items-center justify-center mb-6">
          <Square className="w-8 h-8 text-amber-700 mr-2" />
          <h1 className="text-2xl font-bold text-gray-800">Flooring Calculator</h1>
        </div>

        <div className="space-y-6">
          {/* Room Name Input */}
          <div>
            <label className="block text-sm font-medium text-gray-700">Room Name</label>
            <input
              type="text"
              value={currentRoom.name}
              onChange={(e) => setCurrentRoom({ ...currentRoom, name: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring focus:ring-amber-200"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Flooring Type</label>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
              {(['hardwood', 'laminate', 'tile', 'vinyl', 'carpet'] as const).map((type) => (
                <button
                  key={type}
                  onClick={() => setCurrentRoom({ ...currentRoom, flooringType: type })}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    currentRoom.flooringType === type
                      ? 'bg-amber-700 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {type}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Quality Grade</label>
            <div className="grid grid-cols-3 gap-2">
              {(['economy', 'standard', 'premium'] as const).map((grade) => (
                <button
                  key={grade}
                  onClick={() => setCurrentRoom({ ...currentRoom, qualityGrade: grade })}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    currentRoom.qualityGrade === grade
                      ? 'bg-amber-700 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {grade}
                </button>
              ))}
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Length (feet)</label>
              <input
                type="number"
                value={currentRoom.length}
                onChange={(e) => setCurrentRoom({ ...currentRoom, length: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring focus:ring-amber-200"
                placeholder="Enter length"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Width (feet)</label>
              <input
                type="number"
                value={currentRoom.width}
                onChange={(e) => setCurrentRoom({ ...currentRoom, width: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring focus:ring-amber-200"
                placeholder="Enter width"
              />
            </div>
          </div>

          <div className="flex flex-col space-y-2">
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={currentRoom.includeUnderlayment}
                onChange={(e) => setCurrentRoom({ ...currentRoom, includeUnderlayment: e.target.checked })}
                className="rounded text-amber-700 focus:ring-amber-500"
              />
              <span>Include Underlayment</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={currentRoom.includeRemoval}
                onChange={(e) => setCurrentRoom({ ...currentRoom, includeRemoval: e.target.checked })}
                className="rounded text-amber-700 focus:ring-amber-500"
              />
              <span>Include Floor Removal</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={currentRoom.includeDisposal}
                onChange={(e) => setCurrentRoom({ ...currentRoom, includeDisposal: e.target.checked })}
                className="rounded text-amber-700 focus:ring-amber-500"
              />
              <span>Include Disposal</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={currentRoom.includeTrim}
                onChange={(e) => setCurrentRoom({ ...currentRoom, includeTrim: e.target.checked })}
                className="rounded text-amber-700 focus:ring-amber-500"
              />
              <span>Include Trim/Baseboards</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={currentRoom.needsLeveling}
                onChange={(e) => setCurrentRoom({ ...currentRoom, needsLeveling: e.target.checked })}
                className="rounded text-amber-700 focus:ring-amber-500"
              />
              <span>Needs Floor Leveling</span>
            </label>
          </div>

          <button
            onClick={handleAddRoom}
            disabled={!currentRoom.length || !currentRoom.width}
            className={`w-full py-2 px-4 rounded-lg ${
              !currentRoom.length || !currentRoom.width
                ? 'bg-gray-300 cursor-not-allowed'
                : 'bg-amber-700 hover:bg-amber-800 text-white'
            }`}
          >
            Add Room to Estimate
          </button>

          {/* Room List */}
          {rooms.length > 0 && (
            <div className="space-y-4">
              <h3 className="text-lg font-semibold text-gray-800">Added Rooms</h3>
              {rooms.map((room) => (
                <div key={room.id} className="bg-gray-50 rounded-lg p-4">
                  <div className="flex justify-between items-start">
                    <div>
                      <h4 className="font-medium text-gray-900">{room.name}</h4>
                      <p className="text-sm text-gray-600">
                        {room.flooringType} - {room.qualityGrade} grade
                      </p>
                      <p className="text-sm text-gray-600">
                        {room.length}' × {room.width}'
                        {room.estimate && ` (${room.estimate.dimensions.area} sq ft)`}
                      </p>
                    </div>
                    <div className="text-right">
                      <p className="font-semibold text-amber-700">
                        ${room.estimate?.total}
                      </p>
                      <button
                        onClick={() => removeRoom(room.id)}
                        className="text-sm text-red-600 hover:text-red-800"
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                </div>
              ))}

              <div className="bg-amber-50 p-4 rounded-lg">
                <div className="flex justify-between items-center">
                  <span className="font-bold text-gray-900">Total Project Cost:</span>
                  <span className="text-2xl font-bold text-amber-700">
                    ${calculateTotalCost().toFixed(2)}
                  </span>
                </div>
              </div>
            </div>
          )}

          <div className="bg-amber-50 p-4 rounded-lg space-y-2">
            <div className="flex items-start space-x-2">
              <AlertTriangle className="w-5 h-5 text-amber-500 flex-shrink-0 mt-0.5" />
              <div className="text-sm text-amber-800">
                <p>* Estimates based on average Lima/Dayton area rates. Actual costs may vary based on specific conditions.</p>
                <p>* Additional costs may apply for repairs or special requirements.</p>
                <p>* Material costs may vary based on specific styles and patterns selected.</p>
              </div>
            </div>
          </div>

          {/* Local Flooring Specialist */}
          <div className="bg-white p-4 rounded-lg shadow-sm">
            <h3 className="text-lg font-semibold text-gray-900 mb-4">Local Flooring Specialist</h3>
            <div className="space-y-4">
              <div className="border-b pb-4">
                <h4 className="font-semibold text-amber-700">
                  <a href="https://www.westyssignatureflooring.com" target="_blank" rel="noopener noreferrer" 
                     className="hover:text-amber-600">
                    Westy's Signature Flooring
                  </a>
                </h4>
                <p className="text-gray-600">(419) 629-2233</p>
                <p className="text-gray-600">11 W 2nd St, New Bremen, OH 45869</p>
                <p className="text-sm text-gray-500 mt-1">Expert flooring installation and service in the Minster/Maria Stein area</p>
              </div>
            </div>
          </div>

          <div className="prose prose-sm max-w-none">
            <h3 className="text-lg font-semibold text-gray-900">Understanding Flooring Costs</h3>
            <p className="text-gray-700">
              Flooring costs in the Lima/Dayton area vary based on several factors including material choice, room size, and additional features. Basic installation typically ranges from $3-15 per square foot, with premium materials and additional services increasing costs.
            </p>
            <h4 className="text-md font-semibold text-gray-900 mt-4">Key Considerations</h4>
            <ul className="list-disc pl-5 text-gray-700">
              <li>Material durability and maintenance requirements</li>
              <li>Subfloor condition and preparation needs</li>
              <li>Room layout and complexity</li>
              <li>Existing flooring removal</li>
              <li>Trim and transition pieces</li>
            </ul>
            <p className="text-gray-700 mt-4">
              For the most accurate estimate, we recommend getting quotes from multiple local contractors and considering both immediate costs and long-term maintenance requirements when selecting materials.
            </p>
          </div>
        </div>

        <Footer />
        
        <ShareSection currentUrl={window.location.href} />
      </div>
    </div>
  );
}