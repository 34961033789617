import React, { useState } from 'react';
import { DollarSign, Calculator, FileText, AlertTriangle } from 'lucide-react';
import Footer from '../components/Footer';
import ShareSection from '../components/ShareSection';

type ServiceType = 'tax-return' | 'bookkeeping' | 'payroll' | 'audit' | 'consulting' | 'planning';
type BusinessType = 'individual' | 'sole-prop' | 'partnership' | 'llc' | 'corporation';
type Complexity = 'basic' | 'moderate' | 'complex';

export default function AccountingCalculator() {
  // State for accounting details
  const [serviceType, setServiceType] = useState<ServiceType>('tax-return');
  const [businessType, setBusinessType] = useState<BusinessType>('individual');
  const [complexity, setComplexity] = useState<Complexity>('moderate');
  const [revenue, setRevenue] = useState('');
  const [employeeCount, setEmployeeCount] = useState('0');
  const [transactionVolume, setTransactionVolume] = useState('low');
  const [includeStateReturns, setIncludeStateReturns] = useState(true);
  const [includeQuarterlyReports, setIncludeQuarterlyReports] = useState(false);
  const [needsBackwork, setNeedsBackwork] = useState(false);
  const [includeAdvisory, setIncludeAdvisory] = useState(false);

  // Current market rates (2024 Lima/Dayton area)
  const baseCosts = {
    'tax-return': {
      individual: { basic: 250, moderate: 450, complex: 850 },
      'sole-prop': { basic: 450, moderate: 750, complex: 1250 },
      partnership: { basic: 850, moderate: 1250, complex: 2250 },
      llc: { basic: 750, moderate: 1150, complex: 2000 },
      corporation: { basic: 1250, moderate: 2250, complex: 3500 }
    },
    bookkeeping: {
      monthly: { basic: 250, moderate: 450, complex: 850 },
      quarterly: { basic: 650, moderate: 1250, complex: 2250 },
      annual: { basic: 2250, moderate: 4250, complex: 7500 }
    },
    payroll: {
      baseRate: 100, // per month
      perEmployee: 20 // per employee per month
    },
    audit: {
      basic: 2500,
      moderate: 5000,
      complex: 9500
    },
    consulting: {
      hourly: { basic: 150, moderate: 225, complex: 350 }
    },
    planning: {
      basic: 750,
      moderate: 1500,
      complex: 3000
    }
  };

  // Additional service costs
  const additionalCosts = {
    stateReturns: {
      individual: 125,
      business: 250
    },
    quarterlyReports: {
      basic: 250,
      moderate: 450,
      complex: 750
    },
    backwork: {
      perMonth: 350
    },
    advisory: {
      monthly: 350
    }
  };

  // Volume-based adjustments
  const volumeMultipliers = {
    low: 1.0,
    medium: 1.2,
    high: 1.4
  };

  const calculateEstimate = () => {
    const rev = parseFloat(revenue);
    if (!rev && serviceType !== 'tax-return') return null;

    // Calculate base cost
    let baseCost = 0;
    if (serviceType === 'tax-return') {
      baseCost = baseCosts['tax-return'][businessType][complexity];
    } else if (serviceType === 'bookkeeping') {
      baseCost = baseCosts.bookkeeping.monthly[complexity] * 12;
    } else if (serviceType === 'payroll') {
      const employees = parseInt(employeeCount) || 0;
      baseCost = (baseCosts.payroll.baseRate + (baseCosts.payroll.perEmployee * employees)) * 12;
    } else if (serviceType === 'audit') {
      baseCost = baseCosts.audit[complexity];
    } else if (serviceType === 'consulting') {
      baseCost = baseCosts.consulting.hourly[complexity] * 10; // Assume 10 hours
    } else if (serviceType === 'planning') {
      baseCost = baseCosts.planning[complexity];
    }

    // Apply volume multiplier
    const volumeMultiplier = volumeMultipliers[transactionVolume as keyof typeof volumeMultipliers];
    baseCost *= volumeMultiplier;

    // Calculate additional costs
    const stateReturnsCost = includeStateReturns ? 
      additionalCosts.stateReturns[businessType === 'individual' ? 'individual' : 'business'] : 0;

    const quarterlyReportsCost = includeQuarterlyReports ?
      (additionalCosts.quarterlyReports[complexity] * 4) : 0;

    const backworkCost = needsBackwork ?
      (additionalCosts.backwork.perMonth * 3) : 0; // Assume 3 months average

    const advisoryCost = includeAdvisory ?
      (additionalCosts.advisory.monthly * 12) : 0;

    // Calculate totals
    const subtotal = baseCost + stateReturnsCost + quarterlyReportsCost + 
                    backworkCost + advisoryCost;
    const total = subtotal;

    return {
      base: baseCost.toFixed(2),
      additional: {
        stateReturns: stateReturnsCost.toFixed(2),
        quarterlyReports: quarterlyReportsCost.toFixed(2),
        backwork: backworkCost.toFixed(2),
        advisory: advisoryCost.toFixed(2)
      },
      total: total.toFixed(2)
    };
  };

  const results = calculateEstimate();

  return (
    <div className="min-h-screen bg-gradient-to-b from-emerald-600 to-emerald-800 p-6">
      <div className="max-w-2xl mx-auto bg-white rounded-xl shadow-lg p-6">
        <div className="flex items-center justify-center mb-6">
          <FileText className="w-8 h-8 text-emerald-600 mr-2" />
          <h1 className="text-2xl font-bold text-gray-800">Accounting Cost Calculator</h1>
        </div>

        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Service Type</label>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
              {(['tax-return', 'bookkeeping', 'payroll', 'audit', 'consulting', 'planning'] as const).map((type) => (
                <button
                  key={type}
                  onClick={() => setServiceType(type)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    serviceType === type
                      ? 'bg-emerald-600 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {type.replace('-', ' ')}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Business Type</label>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
              {(['individual', 'sole-prop', 'partnership', 'llc', 'corporation'] as const).map((type) => (
                <button
                  key={type}
                  onClick={() => setBusinessType(type)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    businessType === type
                      ? 'bg-emerald-600 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {type === 'sole-prop' ? 'Sole Proprietor' : 
                   type === 'llc' ? 'LLC' : type}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Complexity Level</label>
            <div className="grid grid-cols-3 gap-2">
              {(['basic', 'moderate', 'complex'] as const).map((level) => (
                <button
                  key={level}
                  onClick={() => setComplexity(level)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    complexity === level
                      ? 'bg-emerald-600 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {level}
                </button>
              ))}
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Annual Revenue</label>
              <input
                type="number"
                value={revenue}
                onChange={(e) => setRevenue(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring focus:ring-emerald-200"
                placeholder="Enter revenue"
              />
            </div>
            {serviceType === 'payroll' && (
              <div>
                <label className="block text-sm font-medium text-gray-700">Number of Employees</label>
                <input
                  type="number"
                  value={employeeCount}
                  onChange={(e) => setEmployeeCount(e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring focus:ring-emerald-200"
                  placeholder="Enter employee count"
                />
              </div>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Transaction Volume</label>
            <div className="grid grid-cols-3 gap-2">
              {(['low', 'medium', 'high'] as const).map((volume) => (
                <button
                  key={volume}
                  onClick={() => setTransactionVolume(volume)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    transactionVolume === volume
                      ? 'bg-emerald-600 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {volume}
                </button>
              ))}
            </div>
          </div>

          <div className="flex flex-col space-y-2">
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeStateReturns}
                onChange={(e) => setIncludeStateReturns(e.target.checked)}
                className="rounded text-emerald-600 focus:ring-emerald-500"
              />
              <span>Include State Returns</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeQuarterlyReports}
                onChange={(e) => setIncludeQuarterlyReports(e.target.checked)}
                className="rounded text-emerald-600 focus:ring-emerald-500"
              />
              <span>Include Quarterly Reports</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={needsBackwork}
                onChange={(e) => setNeedsBackwork(e.target.checked)}
                className="rounded text-emerald-600 focus:ring-emerald-500"
              />
              <span>Needs Prior Period Work</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeAdvisory}
                onChange={(e) => setIncludeAdvisory(e.target.checked)}
                className="rounded text-emerald-600 focus:ring-emerald-500"
              />
              <span>Include Advisory Services</span>
            </label>
          </div>

          {results && (
            <div className="bg-gray-50 rounded-lg p-6 mt-6">
              <div className="flex items-center justify-center mb-4">
                <Calculator className="w-6 h-6 text-emerald-600 mr-2" />
                <h2 className="text-xl font-semibold text-gray-800">Cost Estimate</h2>
              </div>

              <div className="space-y-4">
                <div className="border-t pt-4 space-y-2">
                  <div className="flex justify-between">
                    <span className="text-gray-600">Base Services:</span>
                    <span className="font-semibold">${results.base}</span>
                  </div>
                  {parseFloat(results.additional.stateReturns) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">State Returns:</span>
                      <span className="font-semibold">${results.additional.stateReturns}</span>
                    </div>
                  )}
                  {parseFloat(results.additional.quarterlyReports) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Quarterly Reports:</span>
                      <span className="font-semibold">${results.additional.quarterlyReports}</span>
                    </div>
                  )}
                  {parseFloat(results.additional.backwork) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Prior Period Work:</span>
                      <span className="font-semibold">${results.additional.backwork}</span>
                    </div>
                  )}
                  {parseFloat(results.additional.advisory) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Advisory Services:</span>
                      <span className="font-semibold">${results.additional.advisory}</span>
                    </div>
                  )}
                </div>

                <div className="border-t pt-4 flex justify-between items-center">
                  <div className="flex items-center">
                    <DollarSign className="w-5 h-5 text-emerald-600 mr-2" />
                    <span className="font-bold">Total Estimate:</span>
                  </div>
                  <span className="text-2xl font-bold text-emerald-600">${results.total}</span>
                </div>
              </div>
            </div>
          )}

          <div className="bg-emerald-50 p-4 rounded-lg space-y-2">
            <div className="flex items-start space-x-2">
              <AlertTriangle className="w-5 h-5 text-emerald-500 flex-shrink-0 mt-0.5" />
              <div className="text-sm text-emerald-800">
                <p>* Estimates based on average Lima/Dayton area rates. Actual costs may vary based on specific requirements.</p>
                <p>* Additional fees may apply for complex situations or expedited services.</p>
                <p>* Consultation recommended for accurate assessment of service needs.</p>
              </div>
            </div>
          </div>
        </div>

        <Footer />
        
        <ShareSection currentUrl={window.location.href} />
      </div>
    </div>
  );
}