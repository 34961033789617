import React, { useState } from 'react';
import { Zap, Calculator, DollarSign, Clock, AlertTriangle } from 'lucide-react';
import Footer from '../components/Footer';
import ShareSection from '../components/ShareSection';

type JobType = 'repair' | 'installation' | 'upgrade' | 'troubleshoot';
type FixtureType = 'outlet' | 'switch' | 'fixture' | 'panel' | 'circuit' | 'ceiling-fan' | 'ev-charger' | 'generator' | 'smart-device';
type PanelSize = '100' | '150' | '200' | '400';
type WireType = 'romex' | 'conduit' | 'underground' | 'aluminum';
type ServiceLevel = 'basic' | 'standard' | 'premium';

interface MaterialCosts {
  [key: string]: {
    [key: string]: { unit: number; labor: number };
  };
}

export default function ElectricalEstimator() {
  // State for job details
  const [jobType, setJobType] = useState<JobType>('repair');
  const [fixtureType, setFixtureType] = useState<FixtureType>('outlet');
  const [serviceLevel, setServiceLevel] = useState<ServiceLevel>('standard');
  const [quantity, setQuantity] = useState<string>('1');
  const [panelSize, setPanelSize] = useState<PanelSize>('200');
  const [wireType, setWireType] = useState<WireType>('romex');
  const [wireLength, setWireLength] = useState<string>('');
  const [emergency, setEmergency] = useState(false);
  const [weekend, setWeekend] = useState(false);
  const [needsPermit, setNeedsPermit] = useState(true);
  const [includeSmartFeatures, setIncludeSmartFeatures] = useState(false);
  const [needsTrenching, setNeedsTrenching] = useState(false);
  const [includeBackup, setIncludeBackup] = useState(false);

  // 2024 Minster/Maria Stein area rates
  const laborRates = {
    base: 95, // per hour
    emergency: 185, // per hour
    weekend: 142.50, // per hour (1.5x base)
    minimum: 175, // minimum service call
    trenching: 12.50, // per linear foot
    inspection: 85 // per visit
  };

  // Material costs by type and quality
  const materialCosts: MaterialCosts = {
    outlet: {
      basic: { unit: 8, labor: 45 },
      standard: { unit: 15, labor: 45 },
      premium: { unit: 25, labor: 45 }
    },
    switch: {
      basic: { unit: 10, labor: 45 },
      standard: { unit: 20, labor: 45 },
      premium: { unit: 45, labor: 45 }
    },
    fixture: {
      basic: { unit: 45, labor: 85 },
      standard: { unit: 85, labor: 85 },
      premium: { unit: 200, labor: 125 }
    },
    panel: {
      basic: { unit: 450, labor: 650 },
      standard: { unit: 750, labor: 650 },
      premium: { unit: 1200, labor: 850 }
    },
    circuit: {
      basic: { unit: 75, labor: 225 },
      standard: { unit: 125, labor: 225 },
      premium: { unit: 200, labor: 275 }
    },
    'ceiling-fan': {
      basic: { unit: 85, labor: 125 },
      standard: { unit: 150, labor: 125 },
      premium: { unit: 300, labor: 175 }
    },
    'ev-charger': {
      basic: { unit: 450, labor: 350 },
      standard: { unit: 650, labor: 350 },
      premium: { unit: 950, labor: 450 }
    },
    generator: {
      basic: { unit: 3500, labor: 850 },
      standard: { unit: 5500, labor: 850 },
      premium: { unit: 8500, labor: 1250 }
    },
    'smart-device': {
      basic: { unit: 65, labor: 85 },
      standard: { unit: 125, labor: 85 },
      premium: { unit: 225, labor: 125 }
    }
  };

  // Wire costs per foot
  const wireCosts = {
    romex: { basic: 2.25, standard: 3.25, premium: 4.50 },
    conduit: { basic: 3.75, standard: 4.75, premium: 6.50 },
    underground: { basic: 4.25, standard: 5.75, premium: 7.50 },
    aluminum: { basic: 1.75, standard: 2.75, premium: 3.75 }
  };

  // Panel upgrade costs
  const panelUpgrades = {
    '100': { cost: 1200, labor: 850 },
    '150': { cost: 1500, labor: 950 },
    '200': { cost: 1800, labor: 1050 },
    '400': { cost: 2800, labor: 1450 }
  };

  // Permit and inspection fees
  const permitFees = {
    basic: 150,
    panel: 250,
    service: 350,
    commercial: 500
  };

  const calculateEstimate = () => {
    if (!quantity) return null;

    // Calculate labor rate based on conditions
    let hourlyRate = laborRates.base;
    if (emergency) hourlyRate = laborRates.emergency;
    else if (weekend) hourlyRate = laborRates.weekend;

    const units = parseInt(quantity);
    const material = materialCosts[fixtureType][serviceLevel];
    
    // Calculate base costs
    let materialCost = material.unit * units;
    let laborCost = material.labor * units;

    // Add wire costs if specified
    if (wireLength) {
      const length = parseFloat(wireLength);
      const wireCost = wireCosts[wireType][serviceLevel];
      materialCost += length * wireCost;
      laborCost += (length / 50) * hourlyRate; // Add labor for every 50ft of wire
    }

    // Add panel upgrade costs if applicable
    if (fixtureType === 'panel') {
      const upgrade = panelUpgrades[panelSize];
      materialCost = upgrade.cost;
      laborCost = upgrade.labor;
    }

    // Add trenching costs if needed
    const trenchingCost = needsTrenching && wireLength ? 
      parseFloat(wireLength) * laborRates.trenching : 0;

    // Add smart features if selected
    const smartCost = includeSmartFeatures ? 
      (materialCosts['smart-device'][serviceLevel].unit + 
       materialCosts['smart-device'][serviceLevel].labor) * units : 0;

    // Add backup power integration if selected
    const backupCost = includeBackup ? 
      (materialCosts.generator[serviceLevel].unit * 0.15) : 0;

    // Calculate permit fees
    const permitCost = needsPermit ? 
      (fixtureType === 'panel' ? permitFees.panel : 
       fixtureType === 'service' ? permitFees.service : 
       permitFees.basic) : 0;

    // Calculate inspection costs
    const inspectionCost = needsPermit ? laborRates.inspection : 0;

    // Calculate totals
    const subtotal = materialCost + laborCost + trenchingCost + 
                    smartCost + backupCost + permitCost + inspectionCost;
    const tax = materialCost * 0.075; // 7.5% sales tax on materials
    const total = subtotal + tax;

    // Calculate time estimate
    const baseHours = material.labor / hourlyRate;
    const wireHours = wireLength ? (parseFloat(wireLength) / 50) : 0;
    const totalHours = (baseHours * units) + wireHours;

    return {
      materials: materialCost.toFixed(2),
      labor: laborCost.toFixed(2),
      trenching: trenchingCost.toFixed(2),
      smart: smartCost.toFixed(2),
      backup: backupCost.toFixed(2),
      permit: permitCost.toFixed(2),
      inspection: inspectionCost.toFixed(2),
      tax: tax.toFixed(2),
      total: total.toFixed(2),
      hours: totalHours.toFixed(1)
    };
  };

  const results = calculateEstimate();

  return (
    <div className="min-h-screen bg-gradient-to-b from-amber-500 to-amber-700 p-6">
      <div className="max-w-2xl mx-auto bg-white rounded-xl shadow-lg p-6">
        <div className="flex items-center justify-center mb-6">
          <Zap className="w-8 h-8 text-amber-500 mr-2" />
          <h1 className="text-2xl font-bold text-gray-800">Electrical Estimator</h1>
        </div>

        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Job Type</label>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
              {(['repair', 'installation', 'upgrade', 'troubleshoot'] as const).map((type) => (
                <button
                  key={type}
                  onClick={() => setJobType(type)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    jobType === type
                      ? 'bg-amber-500 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {type}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Type of Work</label>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
              {(['outlet', 'switch', 'fixture', 'panel', 'circuit', 'ceiling-fan', 
                 'ev-charger', 'generator', 'smart-device'] as const).map((type) => (
                <button
                  key={type}
                  onClick={() => setFixtureType(type)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    fixtureType === type
                      ? 'bg-amber-500 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {type.replace('-', ' ')}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Service Level</label>
            <div className="grid grid-cols-3 gap-2">
              {(['basic', 'standard', 'premium'] as const).map((level) => (
                <button
                  key={level}
                  onClick={() => setServiceLevel(level)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    serviceLevel === level
                      ? 'bg-amber-500 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {level}
                </button>
              ))}
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Quantity</label>
              <input
                type="number"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
                min="1"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring focus:ring-amber-200"
              />
            </div>
            {fixtureType === 'panel' && (
              <div>
                <label className="block text-sm font-medium text-gray-700">Panel Size (Amps)</label>
                <select
                  value={panelSize}
                  onChange={(e) => setPanelSize(e.target.value as PanelSize)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring focus:ring-amber-200"
                >
                  <option value="100">100A</option>
                  <option value="150">150A</option>
                  <option value="200">200A</option>
                  <option value="400">400A</option>
                </select>
              </div>
            )}
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Wire Type</label>
              <select
                value={wireType}
                onChange={(e) => setWireType(e.target.value as WireType)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring focus:ring-amber-200"
              >
                <option value="romex">Romex</option>
                <option value="conduit">Conduit</option>
                <option value="underground">Underground</option>
                <option value="aluminum">Aluminum</option>
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Wire Length (feet)</label>
              <input
                type="number"
                value={wireLength}
                onChange={(e) => setWireLength(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring focus:ring-amber-200"
                placeholder="Optional"
              />
            </div>
          </div>

          <div className="flex flex-col space-y-2">
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={emergency}
                onChange={(e) => {
                  setEmergency(e.target.checked);
                  if (e.target.checked) setWeekend(false);
                }}
                className="rounded text-amber-500 focus:ring-amber-500"
              />
              <span>Emergency Service (Higher Rate)</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={weekend}
                onChange={(e) => {
                  setWeekend(e.target.checked);
                  if (e.target.checked) setEmergency(false);
                }}
                className="rounded text-amber-500 focus:ring-amber-500"
              />
              <span>Weekend Service (1.5x Rate)</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={needsPermit}
                onChange={(e) => setNeedsPermit(e.target.checked)}
                className="rounded text-amber-500 focus:ring-amber-500"
              />
              <span>Include Permit & Inspection</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeSmartFeatures}
                onChange={(e) => setIncludeSmartFeatures(e.target.checked)}
                className="rounded text-amber-500 focus:ring-amber-500"
              />
              <span>Include Smart Features</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={needsTrenching}
                onChange={(e) => setNeedsTrenching(e.target.checked)}
                className="rounded text-amber-500 focus:ring-amber-500"
              />
              <span>Requires Trenching</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeBackup}
                onChange={(e) => setIncludeBackup(e.target.checked)}
                className="rounded text-amber-500 focus:ring-amber-500"
              />
              <span>Include Backup Power Integration</span>
            </label>
          </div>

          {results && (
            <div className="bg-gray-50 rounded-lg p-6 mt-6">
              <div className="flex items-center justify-center mb-4">
                <Calculator className="w-6 h-6 text-amber-500 mr-2" />
                <h2 className="text-xl font-semibold text-gray-800">Cost Estimate</h2>
              </div>

              <div className="space-y-4">
                <div className="flex items-center justify-between text-sm">
                  <div className="flex items-center">
                    <Clock className="w-4 h-4 text-gray-500 mr-2" />
                    <span className="text-gray-600">Estimated Time:</span>
                  </div>
                  <span className="font-semibold">{results.hours} hours</span>
                </div>

                <div className="border-t pt-4 space-y-2">
                  <div className="flex justify-between">
                    <span className="text-gray-600">Materials:</span>
                    <span className="font-semibold">${results.materials}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-600">Labor:</span>
                    <span className="font-semibold">${results.labor}</span>
                  </div>
                  {parseFloat(results.trenching) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Trenching:</span>
                      <span className="font-semibold">${results.trenching}</span>
                    </div>
                  )}
                  {parseFloat(results.smart) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Smart Features:</span>
                      <span className="font-semibold">${results.smart}</span>
                    </div>
                  )}
                  {parseFloat(results.backup) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Backup Integration:</span>
                      <span className="font-semibold">${results.backup}</span>
                    </div>
                  )}
                  {parseFloat(results.permit) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Permit:</span>
                      <span className="font-semibold">${results.permit}</span>
                    </div>
                  )}
                  {parseFloat(results.inspection) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Inspection:</span>
                      <span className="font-semibold">${results.inspection}</span>
                    </div>
                  )}
                  <div className="flex justify-between">
                    <span className="text-gray-600">Sales Tax:</span>
                    <span className="font-semibold">${results.tax}</span>
                  </div>
                </div>

                <div className="border-t pt-4 flex justify-between items-center">
                  <div className="flex items-center">
                    <DollarSign className="w-5 h-5 text-amber-500 mr-2" />
                    <span className="font-bold">Total Estimate:</span>
                  </div>
                  <span className="text-2xl font-bold text-amber-500">${results.total}</span>
                </div>
              </div>
            </div>
          )}

          <div className="bg-white p-4 rounded-lg shadow-sm">
            <h3 className="text-lg font-semibold text-gray-900 mb-4">Local Electrical Specialist</h3>
            <div className="space-y-4">
              <div className="border-b pb-4">
                <h4 className="font-semibold text-amber-700">
                  <a href="https://www.benderelectric.net" target="_blank" rel="noopener noreferrer" 
                     className="hover:text-amber-600">
                    Bender Electric
                  </a>
                </h4>
                <p className="text-gray-600">(419) 678-0002</p>
                <p className="text-gray-600">4950 OH-119, St Henry, OH 45883</p>
                <p className="text-sm text-gray-500 mt-1">Licensed electrical contractor serving the Minster/Maria Stein area</p>
              </div>
            </div>
          </div>

          <div className="bg-amber-50 p-4 rounded-lg space-y-2">
            <div className="flex items-start space-x-2">
              <AlertTriangle className="w-5 h-5 text-amber-500 flex-shrink-0 mt-0.5" />
              <div className="text-sm text-amber-800">
                <p>* Estimates based on average Minster/Maria Stein area rates. Actual costs may vary based on specific conditions.</p>
                <p>* Permit fees may be required and vary by municipality.</p>
                <p>* Some electrical work may require licensed professionals by law.</p>
              </div>
            </div>
          </div>
        </div>

        <Footer />
        
        <ShareSection currentUrl={window.location.href} />
      </div>
    </div>
  );
}