import React, { useState } from 'react';
import { Activity, Calculator, DollarSign, AlertTriangle } from 'lucide-react';
import Footer from '../components/Footer';
import ShareSection from '../components/ShareSection';

type TherapyType = 'evaluation' | 'treatment' | 'specialized' | 'aquatic' | 'sports' | 'pediatric';
type InsuranceType = 'private' | 'medicare' | 'medicaid' | 'workers-comp' | 'self-pay';
type Duration = '30' | '45' | '60' | '90';

export default function PhysicalTherapyCalculator() {
  // State for therapy details
  const [therapyType, setTherapyType] = useState<TherapyType>('treatment');
  const [insuranceType, setInsuranceType] = useState<InsuranceType>('private');
  const [duration, setDuration] = useState<Duration>('60');
  const [sessions, setSessions] = useState('12');
  const [includeModalities, setIncludeModalities] = useState(true);
  const [includeEquipment, setIncludeEquipment] = useState(true);
  const [needsEvaluation, setNeedsEvaluation] = useState(true);
  const [includeHomeExercises, setIncludeHomeExercises] = useState(true);

  // Current market rates (2024 Lima/Dayton area)
  const baseCosts = {
    evaluation: 150,
    treatment: 100,
    specialized: 125,
    aquatic: 135,
    sports: 140,
    pediatric: 130
  };

  // Duration multipliers
  const durationMultipliers = {
    '30': 0.65,
    '45': 0.85,
    '60': 1.0,
    '90': 1.5
  };

  // Insurance adjustments
  const insuranceAdjustments = {
    private: 0.7, // 30% discount
    medicare: 0.55,
    medicaid: 0.45,
    'workers-comp': 0.8,
    'self-pay': 0.85
  };

  // Additional services
  const additionalCosts = {
    modalities: 35, // per session
    equipment: 25,
    homeExercises: 45, // one-time fee
    evaluation: {
      basic: 175,
      complex: 250
    }
  };

  const calculateEstimate = () => {
    const sessionCount = parseInt(sessions);
    if (!sessionCount) return null;

    // Calculate base cost per session
    const baseRate = baseCosts[therapyType];
    const durationMultiplier = durationMultipliers[duration];
    const insuranceMultiplier = insuranceAdjustments[insuranceType];
    
    const sessionCost = baseRate * durationMultiplier * insuranceMultiplier;

    // Calculate additional costs
    const modalitiesCost = includeModalities ? 
      (additionalCosts.modalities * sessionCount) : 0;
    
    const equipmentCost = includeEquipment ?
      (additionalCosts.equipment * sessionCount) : 0;
    
    const evaluationCost = needsEvaluation ?
      (therapyType === 'specialized' || therapyType === 'sports' ? 
        additionalCosts.evaluation.complex : additionalCosts.evaluation.basic) : 0;
    
    const exercisesCost = includeHomeExercises ? additionalCosts.homeExercises : 0;

    // Calculate totals
    const totalPerSession = sessionCost + 
      (modalitiesCost / sessionCount) + 
      (equipmentCost / sessionCount);
    
    const totalCost = (sessionCost * sessionCount) + 
      modalitiesCost + equipmentCost + 
      evaluationCost + exercisesCost;

    // Calculate insurance coverage and patient responsibility
    const insuranceCoverage = insuranceType !== 'self-pay' ? 
      (totalCost * (1 - insuranceMultiplier)) : 0;
    
    const patientResponsibility = totalCost - insuranceCoverage;

    return {
      perSession: totalPerSession.toFixed(2),
      evaluation: evaluationCost.toFixed(2),
      modalities: modalitiesCost.toFixed(2),
      equipment: equipmentCost.toFixed(2),
      exercises: exercisesCost.toFixed(2),
      insurance: insuranceCoverage.toFixed(2),
      patient: patientResponsibility.toFixed(2),
      total: totalCost.toFixed(2)
    };
  };

  const results = calculateEstimate();

  return (
    <div className="min-h-screen bg-gradient-to-b from-cyan-600 to-cyan-800 p-6">
      <div className="max-w-2xl mx-auto bg-white rounded-xl shadow-lg p-6">
        <div className="flex items-center justify-center mb-6">
          <Activity className="w-8 h-8 text-cyan-600 mr-2" />
          <h1 className="text-2xl font-bold text-gray-800">Physical Therapy Calculator</h1>
        </div>

        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Therapy Type</label>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
              {(['evaluation', 'treatment', 'specialized', 'aquatic', 'sports', 'pediatric'] as const).map((type) => (
                <button
                  key={type}
                  onClick={() => setTherapyType(type)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    therapyType === type
                      ? 'bg-cyan-600 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {type}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Insurance Type</label>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
              {(['private', 'medicare', 'medicaid', 'workers-comp', 'self-pay'] as const).map((type) => (
                <button
                  key={type}
                  onClick={() => setInsuranceType(type)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    insuranceType === type
                      ? 'bg-cyan-600 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {type.replace('-', ' ')}
                </button>
              ))}
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Session Duration (minutes)</label>
              <select
                value={duration}
                onChange={(e) => setDuration(e.target.value as Duration)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring focus:ring-cyan-200"
              >
                <option value="30">30 minutes</option>
                <option value="45">45 minutes</option>
                <option value="60">60 minutes</option>
                <option value="90">90 minutes</option>
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Number of Sessions</label>
              <input
                type="number"
                value={sessions}
                onChange={(e) => setSessions(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring focus:ring-cyan-200"
                placeholder="Enter sessions"
              />
            </div>
          </div>

          <div className="flex flex-col space-y-2">
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={needsEvaluation}
                onChange={(e) => setNeedsEvaluation(e.target.checked)}
                className="rounded text-cyan-600 focus:ring-cyan-500"
              />
              <span>Include Initial Evaluation</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeModalities}
                onChange={(e) => setIncludeModalities(e.target.checked)}
                className="rounded text-cyan-600 focus:ring-cyan-500"
              />
              <span>Include Therapeutic Modalities</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeEquipment}
                onChange={(e) => setIncludeEquipment(e.target.checked)}
                className="rounded text-cyan-600 focus:ring-cyan-500"
              />
              <span>Include Equipment Usage</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeHomeExercises}
                onChange={(e) => setIncludeHomeExercises(e.target.checked)}
                className="rounded text-cyan-600 focus:ring-cyan-500"
              />
              <span>Include Home Exercise Program</span>
            </label>
          </div>

          {results && (
            <div className="bg-gray-50 rounded-lg p-6 mt-6">
              <div className="flex items-center justify-center mb-4">
                <Calculator className="w-6 h-6 text-cyan-600 mr-2" />
                <h2 className="text-xl font-semibold text-gray-800">Cost Estimate</h2>
              </div>

              <div className="space-y-4">
                <div className="flex justify-between text-sm">
                  <span className="text-gray-600">Cost per Session:</span>
                  <span className="font-semibold">${results.perSession}</span>
                </div>

                <div className="border-t pt-4 space-y-2">
                  {parseFloat(results.evaluation) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Initial Evaluation:</span>
                      <span className="font-semibold">${results.evaluation}</span>
                    </div>
                  )}
                  {parseFloat(results.modalities) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Therapeutic Modalities:</span>
                      <span className="font-semibold">${results.modalities}</span>
                    </div>
                  )}
                  {parseFloat(results.equipment) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Equipment Usage:</span>
                      <span className="font-semibold">${results.equipment}</span>
                    </div>
                  )}
                  {parseFloat(results.exercises) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Home Exercise Program:</span>
                      <span className="font-semibold">${results.exercises}</span>
                    </div>
                  )}
                </div>

                <div className="border-t pt-4 space-y-2">
                  {parseFloat(results.insurance) > 0 && (
                    <div className="flex justify-between text-green-600">
                      <span>Insurance Coverage:</span>
                      <span className="font-semibold">-${results.insurance}</span>
                    </div>
                  )}
                  <div className="flex justify-between">
                    <span className="text-gray-600">Patient Responsibility:</span>
                    <span className="font-semibold">${results.patient}</span>
                  </div>
                </div>

                <div className="border-t pt-4 flex justify-between items-center">
                  <div className="flex items-center">
                    <DollarSign className="w-5 h-5 text-cyan-600 mr-2" />
                    <span className="font-bold">Total Cost:</span>
                  </div>
                  <span className="text-2xl font-bold text-cyan-600">${results.total}</span>
                </div>
              </div>
            </div>
          )}

          <div className="bg-cyan-50 p-4 rounded-lg space-y-2">
            <div className="flex items-start space-x-2">
              <AlertTriangle className="w-5 h-5 text-cyan-500 flex-shrink-0 mt-0.5" />
              <div className="text-sm text-cyan-800">
                <p>* Estimates based on average Lima/Dayton area rates.</p>
                <p>* Actual costs may vary based on specific insurance plans and coverage.</p>
                <p>* Additional costs may apply for specialized treatments or equipment.</p>
              </div>
            </div>
          </div>
        </div>

        <Footer />
        
        <ShareSection currentUrl={window.location.href} />
      </div>
    </div>
  );
}